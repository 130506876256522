import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="subscription"
export default class extends Controller {
  static targets = [
    "formLicenseApi",
    "formLicenseClassic",
    "divRadioButtonsFederations",
    "apiInfos",
    "inputLicenseNumber",
    "selectLicenseNumber",
    "divSelectLicense",
    "inputDocNumber",
    "selectDocument",
    "inputFile",
    "inputTeamName",
    "captainInput",
    "noCaptainLabel",
    "totalDiv",
    "paymentForm",
    "itemInput",
    "optionQuantity",
    "totalOption",
    "sms",
    "insurance",
    "itemPrice",
    "optionPrice",
    "commissionDiv",
    "donation",
    "promoCodeName",
  ];
  static values = {
    apis: Array,
    user: Number,
    firstname: String,
    lastname: String,
    item: Number,
    sms: Number,
    color: String,
    price: Number,
    fees: Array,
    commissionMethod: String,
    reward: Number,
  };

  // participant
  declare apisValue: Array<number>;
  declare hasApisValue: boolean;
  declare formLicenseApiTarget: HTMLDivElement;
  declare hasFormLicenseApiTarget: boolean;
  declare formLicenseApiHtml: string;
  declare formLicenseClassicTarget: HTMLDivElement;
  declare hasFormLicenseClassicTarget: boolean;
  declare formLicenseClassicHtml: string;
  declare inputTeamNameTarget: HTMLInputElement;
  declare hasInputTeamNameTarget: boolean;
  declare captainInputTargets: HTMLInputElement[];
  declare hasCaptainInputTarget: boolean;
  declare noCaptainLabelTarget: HTMLDivElement;
  declare hasNoCaptainLabelTarget: boolean;

  declare divRadioButtonsFederationsTargets: HTMLInputElement[];
  declare hasDivRadioButtonsFederationsTarget: boolean;
  declare userValue: number;
  declare hasUserValue: boolean;
  declare firstnameValue: string;
  declare hasFirstNameValue: boolean;
  declare lastnameValue: string;
  declare hasLastNameValue: boolean;
  declare itemValue: number;
  declare hasItemValue: boolean;
  declare apiInfosTarget: HTMLDivElement;
  declare hasApiInfosTarget: boolean;
  declare selectLicenseNumberTarget: HTMLSelectElement;
  declare hasSelectLicenseNumberTarget: boolean;
  declare divSelectLicenseTarget: HTMLElement;
  declare hasDivSelectLicenseTarget: boolean;
  declare inputLicenseNumberTarget: HTMLInputElement;
  declare hasInputLicenseNumberTarget: boolean;
  declare inputFileTarget: HTMLInputElement;
  declare hasInputFileTarget: boolean;
  declare smsValue: number;

  declare paymentFormTarget: HTMLFormElement;
  declare hasPaymentFormTarget: boolean;
  declare itemInputTargets: HTMLInputElement[];
  declare hasItemInputTarget: boolean;
  declare colorValue: String;
  declare hasColorValue: boolean;

  // documents
  declare inputDocNumberTarget: HTMLInputElement;
  declare hasInputDocNumberTarget: boolean;
  declare selectDocumentTarget: HTMLSelectElement;
  declare hasSelectDocumentTarget: boolean;

  // options
  declare optionQuantityTarget: HTMLInputElement;
  declare hasOptionQuantityTarget: boolean;
  declare totalOptionTarget: HTMLElement;
  declare hasTotalOptionTarget: boolean;
  declare priceValue: number;
  declare hasPriceValue: boolean;

  // récap
  declare totalDivTarget: HTMLElement;
  declare hasTotalDivTarget: boolean;
  declare commissionDivTarget: HTMLElement;
  declare hasCommissionDivTarget: boolean;
  declare smsTargets: HTMLInputElement[];
  declare hasSmsTarget: boolean;
  declare insuranceTargets: HTMLInputElement[];
  declare hasInsuranceTarget: boolean;
  declare itemPriceTargets: HTMLElement[];
  declare hasItemPriceTarget: boolean;
  declare optionPriceTargets: HTMLElement[];
  declare hasOptionPriceTarget: boolean;
  declare feesValue: Array<number>;
  declare donationTarget: HTMLInputElement;
  declare hasDonationTarget: boolean;
  declare promoCodeNameTarget: HTMLInputElement;
  declare hasPromoCodeNameTarget: boolean;
  declare commissionMethodValue: string;
  declare hasCommissionMethodValue: boolean;
  declare rewardValue: number;
  declare hasRewardValue: boolean;

  connect() {
    if (this.hasFormLicenseApiTarget && this.hasFormLicenseClassicTarget) {
      this.formLicenseApiHtml = this.formLicenseApiTarget.innerHTML;
      this.formLicenseClassicHtml = this.formLicenseClassicTarget.innerHTML;
      this.formLicenseApiTarget.innerHTML = "";
      this.formLicenseClassicTarget.innerHTML = "";
      this.toggleLicenseForm();
    }
    if (this.hasPaymentFormTarget) {
      this.paymentFormTarget.submit();
    }
    if (this.hasItemInputTarget) {
      for (let input of this.itemInputTargets) {
        this.toggleLabelStyle(input);
      }
    }
    this.updateOptionAmount();
    this.getTeamName();
    this.checkCaptainPresence();
    // const itemsInputs = document.querySelectorAll(`[id^="item_order_items["]`);
    // if (itemsInputs) {
    //   for (let input of itemsInputs) {
    //     if (input.checked) {
    //       this.toggleDisabledItems(input);
    //     }
    //   }
    // }
  }

  toggleLicenseForm() {
    if (this.hasApisValue && this.hasFormLicenseApiTarget && this.hasFormLicenseClassicTarget) {
      const federationId = parseInt(this.getFederationId());
      if (federationId == 0) {
        this.formLicenseApiTarget.innerHTML = "";
        this.formLicenseClassicTarget.innerHTML = "";
      } else if (this.apisValue.includes(federationId)) {
        this.formLicenseApiTarget.innerHTML = this.formLicenseApiHtml;
        this.formLicenseClassicTarget.innerHTML = "";
      } else {
        this.formLicenseApiTarget.innerHTML = "";
        this.formLicenseClassicTarget.innerHTML = this.formLicenseClassicHtml;
      }
      this.changeOptionsLicensesSelect(federationId);
    }
  }

  toggleRequired(event) {
    const inputs = document.getElementsByTagName("input");
    for (let input of inputs) {
      if (event.target.value == "") {
        input.setAttribute("required", "required");
      } else {
        input.removeAttribute("required");
      }
    }
  }

  changeLicenseNumber(event) {
    let find_option = false;
    if (event.target == this.selectLicenseNumberTarget) {
      this.inputLicenseNumberTarget.value =
        this.selectLicenseNumberTarget.value != ""
          ? this.selectLicenseNumberTarget.selectedOptions[0].label.split(" - ")[0]
          : "";
    } else if (
      event.target == this.inputLicenseNumberTarget &&
      event.target.value != this.selectLicenseNumberTarget.value
    ) {
      for (let option of this.selectLicenseNumberTarget.options) {
        if (event.target.value == option.label.split(" - ")[0]) {
          this.selectLicenseNumberTarget.value = option.value;
          find_option = true;
        }
        if (!find_option) {
          this.selectLicenseNumberTarget.value = "";
        }
      }
    }
  }

  async callApi(event) {
    const spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.classList.remove("hidden");
    }
    const params = {
      federation_id: this.getFederationId(),
      license_number:
        this.hasInputLicenseNumberTarget && this.inputLicenseNumberTarget.value != ""
          ? this.inputLicenseNumberTarget.value
          : this.selectLicenseNumberTarget.selectedOptions[0].label.split(" - ")[0],
      item_id: this.itemValue,
      user_id: this.userValue,
      user_firstname:
        document.getElementById("user_firstname") != null
          ? document.getElementById("user_firstname")?.value
          : this.firstnameValue,
      user_lastname:
        document.getElementById("user_lastname") != null
          ? document.getElementById("user_lastname")?.value
          : this.lastnameValue,
      user_birthday: document.getElementById("user_birthday")?.value,
      user_gender: document.getElementById("user_gender_female")?.checked ? "female" : "male",
    };
    fetch("/license/api/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        if (spinner) {
          spinner.classList.add("hidden");
        }
        if (json && json.license) {
          this.apiInfosTarget.className = "success-infos";
          this.apiInfosTarget.innerText = "Votre licence a été validée par la fédération";
        } else {
          this.apiInfosTarget.className = "error-infos";
          if (json && json.error) {
            this.apiInfosTarget.innerText = json.error;
          } else {
            this.apiInfosTarget.innerText = "Une erreur est survenue. La vérification est impossible pour le moment";
          }
        }
      });
  }

  async callDocApi(event) {
    const spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.classList.remove("hidden");
    }
    const params = {
      doc_id: this.hasSelectDocumentTarget ? this.selectDocumentTarget.value : null,
      order_id: event.target.dataset.orderId,
      user_id: event.target.dataset.userId,
      doc_number: this.hasInputDocNumberTarget ? this.inputDocNumberTarget.value : null,
      federation_id: event.target.dataset.federationId,
    };
    fetch("/documents/call_api/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        const spinner = document.getElementById("spinner");
        if (spinner) {
          spinner.classList.add("hidden");
        }
        if (json && json.license) {
          this.apiInfosTarget.className = "success-infos";
          this.apiInfosTarget.innerText = "Votre document a été validé par la fédération";
        } else {
          this.apiInfosTarget.className = "error-infos";
          if (json && json.error) {
            this.apiInfosTarget.innerText =
              json.error.trim().length > 0
                ? json.error
                : "Numéro de document incorrect ou informations incomplètes. Merci de vérifier que les données saisies correspondent à votre document (nom, prénom, date de naissance, sexe).";
          } else {
            this.apiInfosTarget.innerText = "Une erreur est survenue. La vérification est impossible pour le moment";
          }
        }
      });
  }

  getFederationId() {
    if (this.hasDivRadioButtonsFederationsTarget) {
      for (let rb of this.divRadioButtonsFederationsTargets) {
        if (rb.checked) {
          return rb.value;
        }
      }
    }
  }

  changeOptionsLicensesSelect(federationId) {
    if (this.hasSelectLicenseNumberTarget) {
      const params = {
        federation_id: federationId,
        item_id: this.itemValue,
        user_id: this.userValue,
      };
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.classList.remove("hidden");
      }
      fetch("/license/valid_licenses/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (response.ok) {
            this.selectLicenseNumberTarget.innerHTML = "";
            return response.json();
          }
        })
        .then((json) => {
          if (!json || json.length == 0) {
            let option = document.createElement("option");
            option.label = "Aucune licence correspondante";
            option.value = "";
            this.selectLicenseNumberTarget.options.add(option);
            if (this.hasDivSelectLicenseTarget) {
              this.divSelectLicenseTarget.classList.add("hidden");
            }
          } else {
            let option = document.createElement("option");
            option.label = "Enregistrer une nouvelle licence";
            option.value = "";
            this.selectLicenseNumberTarget.options.add(option);
            for (let license of json) {
              let option = document.createElement("option");
              option.label = license[0];
              option.value = license[1];
              option.selected = license == json[0];
              this.selectLicenseNumberTarget.options.add(option);
            }
            if (this.hasDivSelectLicenseTarget) {
              this.divSelectLicenseTarget.classList.remove("hidden");
            }
            this.selectLicenseNumberTarget.dispatchEvent(new Event("change"));
          }
          if (spinner) {
            spinner.classList.add("hidden");
          }
        });
    }
  }

  deleteLicenseId(event) {
    if (event.target.value != "" && this.hasSelectLicenseNumberTarget) {
      this.selectLicenseNumberTarget.value = "";
    }
  }

  hideSelectLicense(event) {
    if (this.hasDivSelectLicenseTarget && this.hasSelectLicenseNumberTarget && event.target.value != "") {
      this.selectLicenseNumberTarget.value = "";
      this.selectLicenseNumberTarget.dispatchEvent(new Event("change"));
      this.divSelectLicenseTarget.classList.add("hidden");
    }
  }

  toggleSms(event) {
    if (this.hasTotalDivTarget) {
      let total = parseFloat(this.totalDivTarget.innerText.split(" ")[0].replace(",", "."));
      if (event.target.checked) {
        total += this.smsValue;
      } else {
        total -= this.smsValue;
      }
      this.totalDivTarget.innerText = total.toFixed(2).toString() + " €";
    }
  }

  toggleInsurance(event) {
    if (this.hasTotalDivTarget) {
      let total = parseFloat(this.totalDivTarget.innerText.split(" ")[0].replace(",", "."));
      let insuranceValue = parseFloat(event.target.dataset["insuranceValue"]);
      if (event.target.checked) {
        total += insuranceValue;
      } else {
        total -= insuranceValue;
      }
      this.totalDivTarget.innerText = total.toFixed(2).toString() + " €";
    }
  }

  toggleItem(event) {
    // this.toggleDisabledItems(event.target);
    if (event.target.type == "checkbox") {
      this.toggleLabelStyle(event.target);
    } else if (this.hasItemInputTarget) {
      for (let input of this.itemInputTargets) {
        this.toggleLabelStyle(input);
      }
    }
  }

  toggleLabelStyle(targetInput: HTMLInputElement) {
    let label;
    if (targetInput.labels != undefined) {
      label = targetInput.labels[0];
    }
    if (label != null) {
      let container = label.getElementsByClassName("container")[0];
      let circle = label.getElementsByClassName("circle")[0];
      const containerBaseClass = container.className.replace(/border-[a-z]+/g, "");
      const circleBaseClass =
        "circle w-12 grow-0 shrink-0 flex items-center justify-center aspect-square rounded-full text-white";
      const check =
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"/></svg>';
      if (targetInput.checked) {
        container.className = containerBaseClass + ` border${this.colorValue}`;
        circle.className = circleBaseClass + ` bg${this.colorValue}`;
        circle.innerHTML = check;
      } else {
        container.className = containerBaseClass + " border-transparent";
        circle.className = circleBaseClass + ` border${this.colorValue} border-2`;
        circle.innerHTML = "";
      }
    }
  }

  toggleDisabledItems(targetInput: HTMLInputElement) {
    let itemsIds = targetInput.dataset["itemsSameTime"];
    let array = itemsIds
      // on enlève les []
      .substr(1, itemsIds.length - 2)
      .split(",");
    for (let itemId of array) {
      let input = document.getElementById(`item_order_items[${itemId}]`);
      if (targetInput.checked && input != null) {
        input.checked == false;
        input?.setAttribute("disabled", "disabled");
      } else {
        input?.removeAttribute("disabled");
      }
    }
  }

  updateOptionAmount() {
    if (this.hasOptionQuantityTarget && this.hasTotalOptionTarget && this.hasPriceValue) {
      let quantity = this.optionQuantityTarget.value == "" ? 0 : parseFloat(this.optionQuantityTarget.value);
      let optionPrice = quantity * this.priceValue;
      this.totalOptionTarget.innerText = optionPrice > 0 ? `${optionPrice.toFixed(2).replace(".", ",")} €` : "";
    }
    this.updateTotalOptions();
  }

  updateTotalOptions() {
    let total = 0;
    for (let div of document.getElementsByClassName("total-option")) {
      if (div.innerHTML != "") {
        total += parseFloat(div.innerHTML.split(" ")[0].replace(",", "."));
      }
    }
    let divTotalOptions = document.getElementById("total-options");
    if (divTotalOptions != null) {
      divTotalOptions.innerText = `Total des options: ${total.toFixed(2).replace(".", ",")} €`;
    }
  }

  updateSummaryOptions(event) {
    let unitPrice = parseFloat(event.target.dataset.unitPrice);

    const divPriceOption = document.getElementById(`price-option-${event.target.dataset.optionId}`);
    if (divPriceOption != null) {
      if (event.target.value == "") {
        divPriceOption.innerText = "0,00 €";
      } else {
        divPriceOption.innerText = `${(parseInt(event.target.value) * unitPrice).toFixed(2).replace(",", ".")} €`;
      }
    }
    this.updateTotalAndCommission();
  }

  updateTotalAndCommission() {
    let total = 0.0;
    let commission = 0.0;
    let totalItems = 0.0;
    let totalOptions = 0.0;
    let nb_registrations = 0;
    let reward = this.rewardValue;
    const fees_base = this.feesValue[0];
    const fees_percent = this.feesValue[1];
    const fees_min = this.feesValue[2];

    if (this.hasItemPriceTarget) {
      for (let price of this.itemPriceTargets) {
        if (price.dataset.value != null && parseFloat(price.dataset.value) > 0) {
          let priceAmount: number = parseFloat(price.dataset.value);
          if (reward && reward > 0.0) {
            if (reward >= priceAmount) {
              reward -= priceAmount;
              priceAmount = 0.0;
            } else {
              priceAmount -= reward;
              reward = 0.0;
            }
          }
          if (priceAmount > 0.0) {
            nb_registrations += 1;
          }
          totalItems += priceAmount;
        }
      }
    }
    if (this.hasOptionPriceTarget) {
      for (let opt of this.optionPriceTargets) {
        if (opt.innerText != "") {
          totalOptions += parseFloat(opt.innerText.split(" ")[0].replace(",", "."));
        }
      }
      if (reward && reward > 0) {
        totalOptions = reward >= totalOptions ? 0.0 : totalOptions - reward;
      }
    }

    if (this.hasCommissionMethodValue && this.commissionMethodValue == "participant") {
      commission = ((totalItems + totalOptions) * fees_percent) / 100 + nb_registrations * fees_base;
      commission = commission == 0 || commission > fees_min || this.rewardValue ? commission : fees_min;
    }

    total += totalItems + totalOptions + commission;

    if (this.hasSmsTarget) {
      for (let smsCb of this.smsTargets) {
        if (smsCb.checked) {
          total += this.smsValue;
        }
      }
    }
    if (this.hasInsuranceTarget) {
      for (let insuranceCb of this.insuranceTargets) {
        if (insuranceCb.checked && insuranceCb.dataset.insuranceValue != null) {
          total += parseFloat(insuranceCb.dataset.insuranceValue);
        }
      }
    }
    if (this.hasDonationTarget && this.donationTarget.value != "") {
      total += parseFloat(this.donationTarget.value);
    }
    if (this.hasTotalDivTarget) {
      this.totalDivTarget.innerText = total.toFixed(2).toString() + " €";
    }
    if (this.hasCommissionDivTarget) {
      this.commissionDivTarget.innerText = commission.toFixed(2).toString() + " €";
    }
  }

  saveTeamName(event) {
    if (event.target.value != null && event.target.value != "") {
      sessionStorage.setItem("teamName", event.target.value);
    } else {
      sessionStorage.removeItem("teamName");
    }
  }

  getTeamName() {
    if (this.hasInputTeamNameTarget) {
      if (sessionStorage.getItem("teamName")) {
        this.inputTeamNameTarget.value = sessionStorage.getItem("teamName");
        sessionStorage.removeItem("teamName");
      } else {
        this.inputTeamNameTarget.remove();
      }
    }
  }

  checkCaptainPresence() {
    if (this.hasCaptainInputTarget && this.hasNoCaptainLabelTarget) {
      const noCaptain = this.captainInputTargets.every((input) => {
        return !input.checked;
      });
      this.captainInputTargets.map((input) => {
        input.required = noCaptain;
      });
      noCaptain
        ? this.noCaptainLabelTarget.classList.remove("invisible")
        : this.noCaptainLabelTarget.classList.add("invisible");
    }
  }

  selectCaptain(event) {
    if (this.hasCaptainInputTarget && event.target.checked) {
      for (let checkbox of this.captainInputTargets) {
        if (event.target.id != checkbox.id) {
          checkbox.checked = false;
        }
      }
    }
    this.checkCaptainPresence();
  }

  applyPromoCode(event) {
    if (this.hasPromoCodeNameTarget && this.promoCodeNameTarget.value != "") {
      // On récupère l'url et la locale si présente
      const baseUrl = document.location.href.split("evt")[0];
      const orderId = event.target.dataset.value;
      let promoCode = this.promoCodeNameTarget.value;
      document.location.href = baseUrl + `item_orders/${orderId}/apply_promo/${promoCode}`;
    }
  }
}
