import { Controller } from "@hotwired/stimulus";
import Croppr from "croppr";

let croppr;
let cropprBanner;
let cropprPoster;
// Connects to data-controller="pictures"
export default class extends Controller {
  static targets = [
    "cropperBanner",
    "cropperPoster",
    "cropper",
    "resizeDatasBanner",
    "resizeDatasPoster",
    "resizeDatas",
    "preview",
    "previewPdf",
    "trigger",
  ];
  static values = { ratio: Number, width: Number, height: Number };

  declare cropperBannerTarget: HTMLImageElement;
  declare hasCropperBannerTarget: boolean;
  declare cropperPosterTarget: HTMLImageElement;
  declare hasCropperPosterTarget: boolean;
  declare cropperTarget: HTMLImageElement;
  declare hasCropperTarget: boolean;
  declare emptyCropperTarget: HTMLDivElement;
  declare hasEmptyCropperTarget: boolean;
  declare resizeDatasBannerTarget: HTMLInputElement;
  declare hasResizeDatasBannerTarget: boolean;
  declare resizeDatasPosterTarget: HTMLInputElement;
  declare hasResizeDatasPosterTarget: boolean;
  declare resizeDatasTarget: HTMLInputElement;
  declare hasResizeDatasTarget: boolean;

  declare previewTarget: HTMLImageElement;
  declare hasPreviewTarget: boolean;
  declare previewPdfTarget: HTMLObjectElement;
  declare hasPreviewPdfTarget: boolean;
  declare triggerTarget: HTMLSelectElement;
  declare hasTriggerTarget: boolean;

  declare ratioValue: number;
  declare widthValue: number;
  declare heightValue: number;

  connect() {
    this.preview();
  }

  handleFile(event) {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        if (typeof imageUrl === "string") {
          if (imageUrl.includes("data:application/pdf") && this.hasPreviewPdfTarget) {
            this.previewPdfTarget.data = imageUrl;
            this.previewPdfTarget.classList.remove("hidden");
            if (this.hasPreviewTarget) {
              this.previewTarget.classList.add("hidden");
            }
          } else if (this.hasPreviewTarget) {
            this.previewTarget.src = imageUrl;
            this.previewTarget.classList.remove("hidden");
            this.previewTarget?.parentElement?.classList.remove("hidden");
            if (this.hasPreviewPdfTarget) {
              this.previewPdfTarget.classList.add("hidden");
            }
          } else {
            if (input.id == "inputBanner") {
              if (this.hasCropperBannerTarget && this.hasResizeDatasBannerTarget) {
                this.cropperBannerTarget.src = imageUrl;
                cropprBanner = this.createCropper(this.cropperBannerTarget, this.resizeDatasBannerTarget);
                cropprBanner.containerEl.parentElement.classList.remove("hidden");
              } else {
                cropprBanner.setImage(imageUrl);
                cropprBanner.reset;
                setTimeout(() => {
                  this.resizeDatasBannerTarget.value = setInputDatas(cropprBanner.getValue());
                }, 100);
              }
            } else if (input.id == "inputPoster") {
              if (this.hasCropperPosterTarget && this.hasResizeDatasPosterTarget) {
                this.cropperPosterTarget.src = imageUrl;
                cropprPoster = this.createCropper(this.cropperPosterTarget, this.resizeDatasPosterTarget);
                cropprPoster.containerEl.parentElement.classList.remove("hidden");
              } else {
                cropprPoster.setImage(imageUrl);
                cropprPoster.reset;
                setTimeout(() => {
                  this.resizeDatasPosterTarget.value = setInputDatas(cropprPoster.getValue());
                }, 100);
              }
            } else {
              if (this.hasCropperTarget && this.hasResizeDatasTarget) {
                this.cropperTarget.src = imageUrl;
                croppr = this.createCropper(this.cropperTarget, this.resizeDatasTarget);
                croppr.containerEl.parentElement.classList.remove("hidden");
              } else {
                croppr.setImage(imageUrl);
                croppr.reset;
                setTimeout(() => {
                  this.resizeDatasTarget.value = setInputDatas(croppr.getValue());
                }, 100);
              }
            }
          }
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  createCropper(elt, sizeDatas) {
    const inputDatas = sizeDatas;
    const type = this.hasResizeDatasBannerTarget ? "banner" : this.hasResizeDatasPosterTarget ? "poster" : "other";

    let ratioWidth = parseFloat(elt.naturalWidth) / parseFloat(elt.clientWidth);
    let ratioHeight = parseFloat(elt.naturalHeight) / parseFloat(elt.clientHeight);
    return new Croppr(elt, {
      aspectRatio: this.ratioValue,
      startSize: [50, 50, "%"],
      // minSize: [200, 200, "px"],
      // maxSize: [100, 100, "%"],
      onInitialize(instance: Croppr) {
        instance.moveTo(0.5, 0.5);
        setTimeout(() => {
          inputDatas.value = setInputDatas(instance.getValue());
          inputDatas.dispatchEvent(new Event("input"));
        }, 500);
      },
      onCropEnd(data: Croppr.CropValue) {
        inputDatas.value = setInputDatas(data);
        inputDatas.dispatchEvent(new Event("input"));
      },
    });
  }

  preview() {
    if (this.hasTriggerTarget) {
      const idDoc = this.triggerTarget.value;
      const filename = this.triggerTarget.selectedOptions[0].dataset.filename;
      const labelDoc = this.triggerTarget.selectedOptions[0].label;
      const isPdf = this.triggerTarget.selectedOptions[0].dataset.pdf == "true";
      if (isPdf && this.hasPreviewPdfTarget && idDoc != "") {
        this.previewPdfTarget.data = `/private_data/${idDoc}/${filename}`;
        this.previewPdfTarget.classList.remove("hidden");
        if (this.hasPreviewTarget) {
          this.previewTarget.classList.add("hidden");
        }
      } else if (this.hasPreviewTarget && idDoc != "") {
        this.previewTarget.src = `/private_data/${idDoc}/${filename}`;
        this.previewTarget.alt = labelDoc;
        this.previewTarget.classList.remove("hidden");
        if (this.hasPreviewPdfTarget) {
          this.previewPdfTarget.classList.add("hidden");
        }
      } else {
        this.previewPdfTarget.classList.add("hidden");
        this.previewTarget.classList.add("hidden");
      }
    }
  }
}

function setInputDatas(instanceValue) {
  return `${instanceValue.width}x${instanceValue.height}+${instanceValue.x}+${instanceValue.y};`;
}
