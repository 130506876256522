import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="edit-event"
export default class extends Controller {
  static targets = [
    "pageLink",
    "eventName",
    "eventSlug",
    "available",
    "noDatetimeHour",
    "inputIban",
    "divVariation",
    "optionsForm",
    "inputColorTheme",
    "blockColorTheme",
    "triggerElement",
    "toggleElement",
    "divNotCalOnly",
    "divCalOnly",
    "inputCalOnly",
    "inputBeginDate",
    "inputEndDate",
    "deadlineDate",
    "deadlineTime",
    "divBgColors",
    "bankInput",
    "bankAgreement",
  ];
  static values = { page: String, eventId: Number };

  //général
  declare pageValue: string;
  declare hasPageValue: boolean;
  declare pageLinkTargets: HTMLAnchorElement[];
  declare hasPageLinkTarget: boolean;
  declare eventNameTarget: HTMLInputElement;
  declare hasEventNameTarget: boolean;
  declare eventSlugTarget: HTMLInputElement;
  declare hasEventSlugTarget: boolean;
  declare availableTarget: HTMLElement;
  declare hasAvailableTarget: boolean;
  declare eventIdValue: number;
  declare noDatetimeHourTarget: HTMLInputElement;
  declare hasNoDatetimeHourTarget: boolean;
  declare datetimeInputTarget: HTMLInputElement;
  declare hasDatetimeInputTarget: boolean;
  declare deadlineDateTarget: HTMLInputElement;
  declare hasDeadlineDateTarget: boolean;
  declare deadlineTimeTarget: HTMLInputElement;
  declare hasDeadlineTimeTarget: boolean;

  declare triggerElementTarget: HTMLInputElement;
  declare hasTriggerElementTarget: boolean;
  declare toggleElementTarget: HTMLInputElement;
  declare hasToggleElementTarget: boolean;
  declare divNotCalOnlyTarget: HTMLElement;
  declare hasDivNotCalOnlyTarget: boolean;
  declare divCalOnlyTarget: HTMLElement;
  declare hasDivCalOnlyTarget: boolean;
  declare inputCalOnlyTargets: HTMLInputElement[];
  declare hasInputCalOnlyTarget: boolean;
  declare inputBeginDateTarget: HTMLInputElement;
  declare hasInputBeginDateTarget: boolean;
  declare inputEndDateTarget: HTMLInputElement;
  declare hasInputEndDateTarget: boolean;

  //bank
  declare inputIbanTarget: HTMLInputElement;
  declare hasInputIbanTarget: boolean;
  declare bankInputTargets: HTMLInputElement[];
  declare hasBankInputTarget: boolean;
  declare bankAgreementTarget: HTMLInputElement;
  declare hasBankAgreementTarget: boolean;

  //options
  declare divVariationTargets: HTMLElement[];
  declare hasDivVariationTarget: boolean;
  declare optionsFormTarget: HTMLFormElement;
  declare hasOptionsFormTarget: boolean;
  variationForm = null;

  declare inputColorThemeTargets: HTMLInputElement[];
  declare hasInputColorThemeTarget: boolean;
  declare blockColorThemeTargets: HTMLElement[];
  declare hasBlockColorThemeTarget: boolean;
  declare divBgColorsTarget: HTMLElement;
  declare hasDivBgColorsTarget: boolean;

  connect() {
    //changement des classes des liens vers les différentes pages suivant la page actuelle
    for (let link of this.pageLinkTargets) {
      let pageLink = link.getAttribute("href");
      if (pageLink.includes(this.pageValue)) {
        link.className = "tab-selected";
      } else {
        link.className = "tab";
      }
    }
    this.slugByName();
    this.formatIban();
    this.selectColor();
    if (this.hasDivVariationTarget) {
      this.variationForm = this.divVariationTargets[0].outerHTML;
      this.hideEmptyVariationForm();
    }
    this.toggleElement();
    this.toggleRegistrationsFields();
    // On coche le radiobutton correspondant à la couleur de fond
    if (this.hasDivBgColorsTarget) {
      const radioButtons = document.getElementsByName("event[background_to_array][color]");
      let color = this.divBgColorsTarget.dataset.bgcolor;
      if (color == "rgb(255, 255, 255)" || color == null || color == "#ffffff") {
        color = "";
      }
      for (let rb of radioButtons) {
        if (rb.value == color) {
          rb.checked = "checked";
        }
      }
    }
  }

  slugByName() {
    if (this.hasEventNameTarget && this.hasEventSlugTarget) {
      if (this.eventSlugTarget.value == "") {
        this.eventNameTarget.addEventListener("input", () => {
          this.eventSlugTarget.value = this.eventNameTarget.value
            .normalize("NFD")
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9-_]/g, "")
            .replace(/-+/g, "-")
            .replace(/_+/g, "_")
            .toLowerCase();
          this.testSlug();
        });
      }
    }
  }

  async availableSlug(slug) {
    if (this.hasAvailableTarget) {
      this.availableTarget.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" /></svg>';
      this.availableTarget.className = "waiting-infos";
    }
    fetch("/ajax_slug/" + slug, {
      method: "GET",
      headers: { "Content-type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        if (this.hasAvailableTarget && (json.data == true || json.data == this.eventIdValue)) {
          this.availableTarget.innerText = "Disponible";
          this.availableTarget.className = "success-infos";
        } else {
          this.availableTarget.innerText = "Non disponible";
          this.availableTarget.className = "error-infos";
        }
      });
  }

  testSlug() {
    if (this.hasEventSlugTarget && this.eventSlugTarget.value != "") {
      this.eventSlugTarget.value = this.eventSlugTarget.value
        .normalize("NFD")
        // .replace(/[\u0300-\u036f]/g, "")
        // .replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/g, "")
        .replace(/\s/g, "-")
        .replace(/[^a-zA-Z0-9-_]/g, "")
        .replace(/-+/g, "-")
        .replace(/_+/g, "_")
        .toLowerCase();
      this.availableSlug(this.eventSlugTarget.value);
    } else if (this.hasEventSlugTarget) {
      this.availableTarget.innerText = "Obligatoire";
      this.availableTarget.className = "error-infos";
    }
  }

  setDeadlineDate() {
    if (
      this.hasInputBeginDateTarget &&
      this.hasDeadlineDateTarget &&
      this.inputBeginDateTarget.value != "" &&
      (this.deadlineDateTarget.value == "" || this.deadlineDateTarget.value == null)
    ) {
      const beginDate = new Date(this.inputBeginDateTarget.value);
      beginDate.setDate(beginDate.getDate() - 1);
      this.deadlineDateTarget.value = beginDate.toISOString().slice(0, 10);
      if (this.hasDeadlineTimeTarget && this.deadlineTimeTarget.value == "") {
        this.deadlineTimeTarget.value = "23:59";
      }
    }
  }

  // toggleDatetimeHour() {
  //   let inputs = this.hasDatetimeBeginInputTarget
  //     ? [this.datetimeBeginInputTarget, this.datetimeInputTarget]
  //     : [this.datetimeInputTarget];

  //   for (let input of inputs) {
  //     let date = input.value;
  //     if (this.hasNoDatetimeHourTarget) {
  //       if (this.noDatetimeHourTarget.checked) {
  //         input.type = "date";
  //         //transforme datetime au format date
  //         input.value = date.slice(0, 10);
  //       } else {
  //         input.type = "datetime-local";
  //         // transforme date au format datetime (heure = 23h59)
  //         input.value =
  //           this.hasDatetimeBeginInputTarget && input == this.datetimeBeginInputTarget
  //             ? date + "T00:00"
  //             : date + "T23:59";
  //       }
  //     }
  //   }
  // let date = this.datetimeInputTarget.value;
  // if (this.hasNoDatetimeHourTarget) {
  //   if (this.noDatetimeHourTarget.checked) {
  //     this.datetimeInputTarget.type = "date";
  //     //transforme datetime au format date
  //     this.datetimeInputTarget.value = date.slice(0, 10);
  //   } else {
  //     this.datetimeInputTarget.type = "datetime-local";
  //     // transforme date au format datetime (heure = 23h59)
  //     this.datetimeInputTarget.value = date + "T23:59";
  //   }
  // }
  // }

  displayElt(elt: HTMLElement) {
    if (elt.classList.contains("hidden")) {
      elt.classList.remove("hidden");
    }
  }

  hideElt(elt: HTMLElement) {
    if (!elt.classList.contains("hidden")) {
      elt.classList.add("hidden");
    }
  }

  formatIban() {
    if (this.hasInputIbanTarget && this.inputIbanTarget.value != "") {
      let iban = this.inputIbanTarget.value.replace(/\s/g, "");
      this.inputIbanTarget.value = iban.match(/.{1,4}/g).join(" ");
    }
  }
  addVariation() {
    let index = 0;
    if (this.hasDivVariationTarget) {
      let indexes = [];
      for (let div of this.divVariationTargets) {
        indexes.push(parseInt(div.dataset.index));
      }
      index = this.divVariationTargets.length;
      while (indexes.includes(index)) {
        index++;
      }
    }
    if (this.hasDivVariationTarget || this.variationForm != null) {
      let text = this.variationForm.replaceAll(/variations_attributes_\d{1,3}/g, `variations_attributes_${index}`);
      text = text.replaceAll(/\[variations_attributes\]\[\d{1,3}\]/g, `[variations_attributes][${index}]`);
      text = text.replaceAll(/data-index="\d{1,3}\"/g, `data-index="${index}"`);
      document.getElementById("variations").insertAdjacentHTML("beforeend", text);
      document.getElementById(`item_event_option_variations_attributes_${index}_name`).value = null;
      document.getElementById(`item_event_option_variations_attributes_${index}_price`).value = null;
    }
  }

  removeVariation(event) {
    const divParent = event.target.parentElement.parentElement.parentElement;
    if (divParent.hasAttribute("data-edit-event-target")) {
      divParent.remove();
    }
  }

  hideEmptyVariationForm() {
    if (this.hasDivVariationTarget && this.divVariationTargets.length == 1) {
      let index = parseInt(this.divVariationTargets[0].dataset.index);
      let inputName = document.getElementById(`item_event_option_variations_attributes_${index}_name`);
      let inputPrice = document.getElementById(`item_event_option_variations_attributes_${index}_price`);
      if (inputName.value.trim() == "" && inputPrice.value.trim() == "") {
        this.hideElt(this.divVariationTargets[0]);
      }
    }
  }

  removeHiddenForm() {
    if (this.hasDivVariationTarget && this.divVariationTargets[0].classList.contains("hidden")) {
      this.divVariationTargets[0].remove();
    }
  }

  checkColor(event) {
    if (this.hasBlockColorThemeTarget) {
      const color = event.target.getAttribute("data-value");
      document.getElementById(`event_theme_attributes_color_${color}`).checked = "checked";
      this.selectColor();
    }
  }

  selectColor() {
    if (this.hasBlockColorThemeTarget) {
      let colorSelected = "";
      for (let input of this.inputColorThemeTargets) {
        if (input.checked) {
          colorSelected = input.value;
        }
      }
      for (let block of this.blockColorThemeTargets) {
        if (block.getAttribute("data-value") == colorSelected) {
          block.classList.remove("border-gray-300");
          block.classList.remove("hover:border-gray-500");
          block.classList.remove("border-2");
          block.classList.add("border-gray-900");
          block.classList.add("hover:border-gray-500");
          block.classList.add("border-4");
        } else {
          block.classList.add("border-gray-300");
          block.classList.add("hover:border-gray-500");
          block.classList.add("border-2");
          block.classList.remove("border-gray-900");
          block.classList.remove("hover:border-gray-500");
          block.classList.remove("border-4");
        }
      }
    }
  }

  toggleElement() {
    if (this.hasTriggerElementTarget && this.hasToggleElementTarget) {
      if (this.triggerElementTarget.value.length > 0) {
        this.toggleElementTarget.classList.remove("hidden");
      } else {
        this.toggleElementTarget.classList.add("hidden");
      }
    }
  }

  toggleRegistrationsFields() {
    if (this.hasDivNotCalOnlyTarget && this.hasInputCalOnlyTarget) {
      for (let radioButton of this.inputCalOnlyTargets) {
        if (radioButton.value == "true") {
          if (radioButton.checked) {
            this.divNotCalOnlyTarget.classList.add("hidden");
            this.divCalOnlyTarget.classList.remove("hidden");
          } else {
            this.divNotCalOnlyTarget.classList.remove("hidden");
            this.divCalOnlyTarget.classList.add("hidden");
          }
        }
      }
    }
  }

  fillEndDate() {
    if (
      this.hasInputBeginDateTarget &&
      this.hasInputEndDateTarget &&
      this.inputBeginDateTarget.value != "" &&
      (this.inputEndDateTarget.value == null || this.inputEndDateTarget.value == "")
    ) {
      this.inputEndDateTarget.value = this.inputBeginDateTarget.value;
    }
  }

  checkBankForm(event) {
    event.preventDefault();
    const form = document.getElementsByTagName("form")[0];
    if (this.hasBankInputTarget) {
      let filledInputs = this.bankInputTargets.filter((input) => {
        input.value != "";
      });
      if (filledInputs.length > 0) {
        this.bankAgreementTarget.required = true;
      }
    }
    form.submit();
  }
}
